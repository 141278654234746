<template>
    <n-popover
        trigger="click"
        placement="top-start"
        :show-arrow="false"
        raw
        @update:show="onPopoverShowUpdate">
        <template #trigger>
            <div class="diff-table-td">{{ props.name }}</div>
        </template>
        <div class="tips-view">
            <n-scrollbar :style="{ maxWidth: maxWidth + 'px' }" x-scrollable>
                <div class="diff-columns">
                    <div class="column">
                        <div class="label" style="background-color: #e5e5e5">
                            ->
                        </div>
                        <div class="label">基准</div>
                        <div class="label">比对</div>
                        <div class="label">差值</div>
                    </div>
                    <div
                        v-for="item in columnList"
                        :class="{
                            column: true,
                        }">
                        <div class="column-name">{{ item.label }}</div>
                        <div class="value">
                            {{ props.diffData[item.key].value }}
                        </div>
                        <div class="value">
                            {{ props.diffData[item.key].dst_value }}
                        </div>
                        <div
                            :class="{
                                value: true,
                                red: props.diffData[item.key].signal,
                                green: !props.diffData[item.key].signal,
                            }">
                            {{ props.diffData[item.key].diff }}
                        </div>
                    </div>
                </div>
            </n-scrollbar>
        </div>
    </n-popover>
</template>

<style lang="less" scoped>
@import '../../../../common/common.less';

.diff-table-td {
    padding: 1px 4px 1px;
    word-break: break-all;
    // 定死默认cell高度，解决td cell无法实现text-overflow的问题
    height: 22px;
    overflow: hidden;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}

.tips-view {
    background-color: #fff;
    padding: 4px 8px 2px;
    margin-bottom: -4px;

    .diff-columns {
        display: flex;
        flex-direction: row;
        .column {
            border-right: 1px solid @standard-border-color;
            width: auto;
            position: relative;

            .column-padding {
                padding: 0 6px;
            }

            &:last-child {
                border: none;
            }

            .column-name {
                text-align: right;
                font-weight: bold;
                white-space: nowrap;
                min-width: 60px;
                border-bottom: 1px solid @standard-border-color;
                background-color: #e5e5e5;
                .column-padding;
            }

            .value {
                text-align: right;
                white-space: nowrap;
                border-bottom: 1px solid @standard-border-color;
                .column-padding;

                &.red {
                    color: red;
                    font-weight: bold;
                }

                &.green {
                    color: green;
                    font-weight: bold;
                }
            }

            .label {
                font-weight: bold;
                white-space: nowrap;
                border-bottom: 1px solid @standard-border-color;
                .column-padding;
            }
        }
    }
}
</style>

<script setup>
import { defineProps, computed, onMounted, ref } from 'vue';
import { NPopover, NScrollbar } from 'naive-ui';

const props = defineProps(['name', 'columns', 'diffData']);

const columnList = computed(() => {
    return props.columns.filter(item => !!props.diffData[item.key]);
});

let maxWidth = ref(800);

function updateMaxWidth() {
    maxWidth.value = document.querySelector('.report-view').clientWidth - 30;
}

function onPopoverShowUpdate(show) {
    console.log(show);
    if (show) {
        updateMaxWidth();
    }
}

onMounted(() => {
    updateMaxWidth();
});
</script>
