<template>
    <n-scrollbar :style="{ height: `${height}px` }" x-scrollable>
        <table class="plain-data-table">
            <!-- <colgroup>
                <col v-for="item in columns" :style="getColgroupStyle(item)" />
            </colgroup> -->
            <thead>
                <tr>
                    <th v-for="item in columns">
                        <div
                            class="plain-table-th"
                            :style="getColumnStyle(item)"
                            :title="item.label">
                            {{ item.label }}
                            <div
                                v-if="item.resizable"
                                class="custom-resizer"></div>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in data" :style="item.$rowStyle">
                    <td v-for="col in columns" :style="getCellStyle(item, col)">
                        <PlainTD :row="item" :column="col"></PlainTD>
                    </td>
                </tr>
            </tbody>
        </table>
    </n-scrollbar>
</template>

<style lang="less" scoped>
.cell-padding {
    padding: 2px 4px 1px;
}
.plain-data-table {
    border-spacing: 0;
    table-layout: fixed;

    thead {
        background-color: #e5e5e5;
        position: sticky;
        z-index: 2;
        top: 0;

        th {
            border-right: 1px solid #fff;
            padding: 0;

            .plain-table-th {
                .cell-padding;
                min-width: 20px;
                position: relative;
                overflow: hidden;
                text-overflow: ellipsis;

                &::-webkit-resizer {
                    width: 16px;
                    height: 16px;
                    background-color: transparent;
                }

                .custom-resizer {
                    width: 4px;
                    height: 80%;
                    position: absolute;
                    bottom: 10%;
                    right: 0;
                    background-color: #ccc;
                    display: none;
                }

                &:hover {
                    .custom-resizer {
                        display: inline;
                    }
                }
            }
        }
    }

    tbody {
        background-color: #fff;
        tr {
            td {
                position: relative;
                padding: 0;
                border-right: 1px solid #efefef;
                border-bottom: 1px solid #efefef;

                &:last-child {
                    border-right: 0;
                }

                .plain-table-td {
                    .cell-padding;
                    word-break: break-all;
                    // 定死默认cell高度，解决td cell无法实现text-overflow的问题
                    height: 22px;
                    overflow: hidden;
                }
            }

            &:last-child {
                td {
                    border-bottom: 0;
                }
            }

            &:hover {
                background-color: #f8f8f8 !important;
            }
        }
    }
}
</style>

<script setup>
import { defineComponent } from 'vue';
import { NScrollbar } from 'naive-ui';

const props = defineProps({
    columns: {
        type: Array,
        default() {
            return [];
        },
    },
    data: {
        type: Array,
        default() {
            return [];
        },
    },
    height: {
        type: Number,
        default: 600,
    },
});

function getColumnStyle(item) {
    return {
        width: typeof item.width === 'number' ? item.width + 'px' : item.width,
        minWidth:
            typeof item.minWidth === 'number'
                ? item.minWidth + 'px'
                : item.minWidth,
        maxWidth:
            typeof item.maxWidth === 'number'
                ? item.maxWidth + 'px'
                : item.maxWidth,
        textAlign: item.align || 'left',
        resize: item.resizable ? 'horizontal' : null,
    };
}

function getCellStyle(item, col) {
    let cellStyle =
        item.$cellStyle &&
        item.$cellStyle[col.key] &&
        item.$cellStyle[col.key].style;
    let style = {
        textAlign: col.align || 'left',
        ...(cellStyle || {}),
    };
    return style;
}

function getColgroupStyle(item) {
    return {
        width: typeof item.width === 'number' ? item.width + 'px' : item.width,
        minWidth:
            typeof item.minWidth === 'number'
                ? item.minWidth + 'px'
                : item.minWidth,
        maxWidth:
            typeof item.maxWidth === 'number'
                ? item.maxWidth + 'px'
                : item.maxWidth,
    };
}

const PlainTD = defineComponent({
    props: ['row', 'column'],
    setup(props) {
        return () => {
            // $cellStyle render具有最高优先级
            if (
                props.row.$cellStyle &&
                props.row.$cellStyle[props.column.key] &&
                props.row.$cellStyle[props.column.key].render
            ) {
                return props.row.$cellStyle[props.column.key].render(props.row);
            }
            // column render具有次高优先级
            if (props.column.render) {
                let res = props.column.render(props.row);
                return typeof res === 'object' ? (
                    res
                ) : (
                    <div class="plain-table-td">{res}</div>
                );
            }
            // 默认render
            return (
                <div class="plain-table-td">{props.row[props.column.key]}</div>
            );
        };
    },
});
</script>
