<template>
    <div class="version-selector">
        <div class="block">
            <div class="label">
                报表月份：
                <div v-show="showDiffSet" class="badge">基准</div>
            </div>
            <n-select
                v-model:value="dateMonth"
                class="input"
                size="small"
                style="width: 90px"
                :options="dateMonthList"
                :consistent-menu-width="false"
                filterable
                @update:value="onDateMonthChange">
            </n-select>
            <div class="label">版本：</div>
            <n-select
                v-model:value="version"
                size="small"
                class="input"
                style="width: 100px"
                :options="versionList"
                :consistent-menu-width="false"
                filterable>
            </n-select>
        </div>
        <div v-show="showDiffSet" class="diff-ver-view block">
            <div class="label">
                报表月份：
                <div
                    v-show="showDiffSet"
                    class="badge"
                    style="background-color: #3ac">
                    比对
                </div>
            </div>
            <n-select
                v-model:value="diffDateMonth"
                class="input"
                size="small"
                style="width: 90px"
                :options="dateMonthList"
                :consistent-menu-width="false"
                filterable
                @update:value="onDiffDateMonthChange">
            </n-select>
            <div class="label">版本：</div>
            <n-select
                v-model:value="diffVersion"
                :options="diffVersionList"
                class="input"
                size="small"
                style="width: 100px"
                trigger="click"
                :consistent-menu-width="false"
                filterable>
            </n-select>
            <n-button
                v-show="showDiffSet"
                quaternary
                circle
                size="tiny"
                @click="toggleDiffSet">
                <template #icon>
                    <n-icon><CloseFilled /></n-icon>
                </template>
            </n-button>
        </div>
        <n-button
            v-show="!showDiffSet"
            class="action"
            quaternary
            size="tiny"
            @click="toggleDiffSet">
            报表比对
            <template #icon>
                <n-icon><DifferenceFilled /></n-icon>
            </template>
        </n-button>
        <n-popover v-if="showDiffSet" placement="bottom-end">
            <template #trigger>
                <n-icon class="action">
                    <HelpFilled />
                </n-icon>
            </template>
            <div class="diff-tips">
                <span style="background-color: #cfc">绿色单元格</span
                >表示基准值相比比对值更小
                <br />
                <span style="background-color: #fcc"> 红色单元格</span
                >表示基准值相比比对值更大
            </div>
        </n-popover>
        <n-button
            :disabled="!ready"
            class="action"
            type="primary"
            size="small"
            @click="onSearch"
            >查询</n-button
        >
        <slot></slot>
    </div>
</template>

<style lang="less" scoped>
@import '../../../../common/common.less';
.version-selector {
    .common-ctrl-bar;

    .badge {
        position: absolute;
        top: -12px;
        left: -16px;
        font-size: 11px;
        line-height: 13px;
        background-color: @primary-color;
        color: #fff;
        border-radius: 12px;
        padding: 1px 4px;
    }

    .diff-ver-view {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #efefef;
        padding: 0 5px 0 10px;
        margin-right: 10px;
        margin-top: -4px;
        border-radius: 4px;

        .label {
            margin-bottom: 0;
        }

        .input {
            margin-bottom: 0;
        }
    }

    .tips {
        padding: 4px 10px;
        border-radius: 10px;
        background-color: #fbf0e1;
        color: #e4a441;
        line-height: 14px;
        margin-left: 10px;
        margin-bottom: 4px;
    }
}
</style>

<script setup>
import { onMounted, ref, computed } from 'vue';

import { useRoute, useRouter } from 'vue-router';

import { NSelect, NButton, NIcon, NPopover, useMessage } from 'naive-ui';

import dayjs from 'dayjs';

import { CloseFilled, HelpFilled, DifferenceFilled } from '@vicons/material';

import { FinancialAPI } from '@/common/API';

const event = defineEmits(['ready', 'change']);

const message = useMessage();

const route = useRoute();

const router = useRouter();

// 查询条件控制
// 是否展现diff选项
let showDiffSet = ref(false);
function toggleDiffSet() {
    showDiffSet.value = !showDiffSet.value;
    diffDateMonth.value = null;
    diffVersion.value = null;
}

let dateVersions = ref([]);

let dateMonthList = computed(() => {
    if (dateVersions.value.length < 1) {
        return [];
    }
    return dateVersions.value.map(item => {
        let label = `${item.year}-${item.month}`;
        return {
            label,
            value: dayjs(`${label}-1`).valueOf(),
        };
    });
});

let versionList = computed(() => {
    if (dateVersions.value.length < 1) {
        return [];
    }
    let date = dayjs(dateMonth.value);
    let year = date.year();
    let month = date.month() + 1;

    let selectedDate = dateVersions.value.find(
        item => item.year === year && item.month === month
    );

    return !selectedDate
        ? []
        : selectedDate.versions.map(item => {
              return { label: item, value: item };
          });
});

let diffVersionList = computed(() => {
    if (dateVersions.value.length < 1) {
        return [];
    }
    let date = dayjs(diffDateMonth.value);
    let year = date.year();
    let month = date.month() + 1;

    let selectedDate = dateVersions.value.find(
        item => item.year === year && item.month === month
    );

    return !selectedDate
        ? []
        : selectedDate.versions.map(item => {
              return { label: item, value: item };
          });
});

// 报表年月
let dateMonth = ref(null);

function onDateMonthChange(value) {
    version.value = versionList.value[0].value;
}

function onDiffDateMonthChange(value) {
    diffVersion.value = diffVersionList.value[0].value;
}

// 报表版本
let version = ref(null);
// 比对报表年月
let diffDateMonth = ref(null);
// 比对报表版本
let diffVersion = ref(null);

function onSearch() {
    let currentDate = dayjs(dateMonth.value);
    let diffDate = showDiffSet.value ? dayjs(diffDateMonth.value) : null;

    let diffVer = diffVersion.value;
    if (showDiffSet.value && !diffVer) {
        message.error('报表版本不能为空');
        return;
    }
    let opt = {
        current: {
            year: currentDate.year(),
            month: currentDate.month() + 1,
            version: version.value,
        },
        ...(showDiffSet.value
            ? {
                  diff: {
                      year: diffDate.year(),
                      month: diffDate.month() + 1,
                      version: diffVersion.value,
                  },
              }
            : {}),
    };
    setQuery(opt);
    event('change', opt);
}

function setQuery(options) {
    let base = `${options.current.year}-${
        options.current.month
    }-${encodeURIComponent(options.current.version)}`;
    let diff = options.diff
        ? `${options.diff.year}-${options.diff.month}-${encodeURIComponent(
              options.diff.version
          )}`
        : null;

    router.replace({ query: { base, ...(diff ? { diff } : {}) } });
}

// lifecycle

let ready = ref(false);

// 加载并初始化数据（加载完之后会向外发送ready事件）
function loadData() {
    FinancialAPI.getDateVersions().then(res => {
        if (res.error_no !== 0) {
            message.error(res.error_msg || '未知异常导致加载失败');
            return;
        }
        ready.value = true;
        dateVersions.value = res.data;
        let dateVer = getCurrentDateVersion();
        dateMonth.value = dayjs(
            `${dateVer.current.year}-${dateVer.current.month}-01`
        ).valueOf();
        version.value = dateVer.current.version;
        if (dateVer.diff) {
            showDiffSet.value = true;
            diffDateMonth.value = dayjs(
                `${dateVer.diff.year}-${dateVer.diff.month}-01`
            ).valueOf();
            diffVersion.value = dateVer.diff.version;
        }
        event('ready', dateVer);
    });
}

function getCurrentDateVersion() {
    // 没有在uri中指定base的，取最近年月的默认版本报表
    if (!route.query.base) {
        let firstYearMonth = dateVersions.value[0];
        return {
            current: {
                year: firstYearMonth.year,
                month: firstYearMonth.month,
                version: firstYearMonth.versions[0],
            },
        };
    }
    let baseInfo = route.query.base.split('-');
    let diffInfo = route.query.diff ? route.query.diff.split('-') : null;
    let res = {
        current: {
            year: baseInfo[0],
            month: baseInfo[1],
            version: decodeURIComponent(baseInfo[2]),
        },
    };
    if (diffInfo) {
        res.diff = {
            year: diffInfo[0],
            month: diffInfo[1],
            version: decodeURIComponent(diffInfo[2]),
        };
    }
    return res;
}

// 刷新版本选项数据（由外部业务调用，不向外发送ready事件）
function refresh() {
    ready.value = false;
    FinancialAPI.getDateVersions()
        .then(res => {
            ready.value = true;
            dateVersions.value = res.data;
        })
        .catch(err => {
            ready.value = true;
        });
}

defineExpose({
    refresh,
});

onMounted(() => {
    loadData();
});
</script>
