<template>
    <div class="report-view">
        <VersionSelector
            ref="vSelector"
            @ready="onVersionReady"
            @change="onVersionChange">
            <n-tag
                v-if="latestDate"
                type="success"
                size="small"
                style="margin-left: 10px"
                round
                >数据统计截止日期：{{ latestDate }}</n-tag
            >
            <n-popover trigger="hover" placement="bottom-end" raw>
                <template #trigger>
                    <n-button size="tiny" style="margin-left: auto" quaternary>
                        <template #icon>
                            <n-icon>
                                <MoreHorizFilled />
                            </n-icon>
                        </template>
                    </n-button>
                </template>
                <div class="settings-view">
                    <n-button size="tiny" quaternary @click="showSnapShotModal">
                        <template #icon>
                            <n-icon>
                                <content-copy-filled />
                            </n-icon>
                        </template>
                        创建报表快照
                    </n-button>
                    <n-button size="tiny" quaternary @click="onCSVExportClick">
                        <template #icon>
                            <n-icon>
                                <table-view-filled />
                            </n-icon>
                        </template>
                        导出Excel
                    </n-button>
                    <div class="value-unit-setting">
                        <div class="label">数值展现单位为“万”</div>
                        <n-switch
                            v-model:value="isValueTransEnable"
                            @update:value="onDisplayTransChange"
                            size="small" />
                    </div>
                </div>
            </n-popover>
        </VersionSelector>
        <div class="table">
            <n-spin :show="loading">
                <PlainDataTable
                    :columns="columns"
                    :data="reportList"
                    :height="tableHeight">
                </PlainDataTable>
            </n-spin>
        </div>
    </div>
    <n-modal v-model:show="showSnapShotCreate" @mask-click="hideSnapShowModal">
        <n-card
            style="width: 600px"
            :title="`创建报表快照[base:${currentReportDate.year}-${currentReportDate.month} realtime]`"
            :bordered="false"
            size="small"
            role="dialog"
            aria-modal="true">
            <n-form
                ref="createSnapShopForm"
                :model="createSnapShowModel"
                :rules="createSnapShopRules"
                label-placement="left">
                <n-form-item label="快照版本" path="versionName">
                    <n-input
                        v-model:value="createSnapShowModel.versionName"
                        type="text"
                        placeholder="填写快照版本名" />
                </n-form-item>
            </n-form>
            <template #footer>
                <n-button
                    style="float: right"
                    type="primary"
                    @click="onCreateSnapShotSubmit"
                    :loading="createSnapLoading">
                    创建
                </n-button>
            </template>
        </n-card>
    </n-modal>
</template>

<style lang="less" scoped>
@import '../../../common/common.less';

.report-view {
    .common-content;
    min-width: 1080px;
    .table {
        margin-top: 10px;
        background-color: #fff;
    }
}

.settings-view {
    width: 150px;
    background-color: #fff;
    padding: 4px 10px 8px;

    .value-unit-setting {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 4px;

        .label {
            font-size: 12px;
            margin-right: 4px;
        }
    }
}
</style>

<script setup>
import { onMounted, ref, h, computed, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {
    NSpin,
    useMessage,
    useDialog,
    NButton,
    NIcon,
    NModal,
    NCard,
    NInput,
    NForm,
    NFormItem,
    NPopover,
    NSwitch,
    NTag,
} from 'naive-ui';
import {
    TableViewFilled,
    ContentCopyFilled,
    MoreHorizFilled,
} from '@vicons/material';

import VersionSelector from './components/VersionSelector.vue';
import DiffCell from './components/DiffCell.vue';
import DiffRow from './components/DiffRow.vue';

import PlainDataTable from '@/components/PlainDataTable';

import { FinancialAPI } from '@/common/API';
import exportCSV from '@/common/CSVGenerator';
import { isNullOrUndefined } from '@/common/tools';

// naive ui message
const message = useMessage();
// naive ui dialog
const dialog = useDialog();
// VersionSelector ref
const vSelector = ref();

const route = useRoute();
const router = useRouter();

// 数据转换控制器工厂（默认转换器mixin自定义转换器）
function translateFactory(config) {
    // 默认的基础数据转换器，支持percent和default（我们默认default为float）
    const translate = {
        percent(value) {
            return (value * 100).toFixed(2) + '%';
        },
        default(value) {
            return value.toFixed(2);
        },
    };
    return { ...translate, ...config };
}

// 根据后端返回的二维数据，转换为前端方便渲染的List数据
function genListByHashAndMap(data, rowMap, config = {}) {
    // 根据数据类型处理展现形式
    const translate = translateFactory(config);
    // 获取表格rowStyle
    function getRowStyle(keyName) {
        const s1 = {
            backgroundColor: '#ccc',
            fontWeight: 'bold',
        };
        const s2 = {
            backgroundColor: '#ddd',
            fontWeight: 'bold',
        };
        const s3 = {
            backgroundColor: '#eee',
        };
        const rowStyleConf = {
            total_ops_cost_ratio: { ...s1 },
            total_ops_cost: { ...s1 },
            total_shared_before_add_bigdata: { ...s1 },
            company_group_grand_total: { ...s1 },
            direct_ops_cost: { ...s2 },
            public_shared_cost: { ...s2 },
            aws_savingplan_unused_shared_ratio: { ...s3 },
            aliyun_savingplan_unused_shared_ratio: { ...s3 },
            cdn_shared_ratio: { ...s3 },
            public_shared_ratio: { ...s3 },
            bigdata_shared_ratio: { ...s3 },
            aliyun_credit_shared_cost: { ...s3 },
            aws_payer1918_shared_credit: { ...s3 },
            bigdata_map_contract_discount: { ...s3 },
            migrate_singapo_map_discount: { ...s3 },
        };

        let rs = rowStyleConf[keyName];

        if (!rs) {
            return {};
        }
        return {
            $rowStyle: { ...rs },
        };
    }
    // 获取表格cellStyle
    function getCellStyle(attrs, name) {
        if (Object.keys(attrs).length < 1) {
            return {};
        }
        let style = {
            $cellStyle: {
                name: {
                    render(row) {
                        return h(DiffRow, {
                            diffData: attrs,
                            name,
                            columns: originResData.columns,
                        });
                    },
                },
            },
        };
        for (let key in attrs) {
            style.$cellStyle[key] = {
                style: {
                    backgroundColor: attrs[key].signal ? '#fcc' : '#cfc',
                    color: '#000',
                },
                render(row) {
                    return h(DiffCell, { data: attrs[key] });
                },
            };
        }
        return style;
    }

    let list = rowMap.map(item => {
        let originAttrs = data[item.key];
        let itemAttrs = {};
        let itemDiffAttrs = {};
        for (let key in originAttrs) {
            let attrObj = originAttrs[key];
            let transFunc =
                translate[attrObj.type || 'default'] || translate['default'];
            itemAttrs[key] = transFunc(attrObj.value, key, item.key);
            if (attrObj.diff !== undefined) {
                itemDiffAttrs[key] = {
                    value: transFunc(attrObj.value, key, item.key),
                    dst_value: transFunc(attrObj.dst_value, key, item.key),
                    diff: transFunc(attrObj.diff, key, item.key),
                    signal: attrObj.diff > 0,
                };
            }
        }
        return {
            keyName: item.key,
            name: item.label,
            ...itemAttrs,
            ...getRowStyle(item.key),
            ...getCellStyle(itemDiffAttrs, item.label),
        };
    });
    return list;
}

function genColumns(columns) {
    let noSourceCol = ['name'];
    let newColumns = columns.map(item => {
        if (noSourceCol.indexOf(item.key) < 0) {
            item.minWidth = isValueTransEnable.value ? 56 : 70;
            item.align = 'right';
        } else {
            item.minWidth = 240;
        }
        item.resizable = true;
        return item;
    });
    return newColumns;
}

// 页面loading状态
let loading = ref(true);
// 表格列定义
let columns = ref([]);
// 表格数据
let reportList = ref([]);

// 表格组件高度（适配屏幕高度）
let tableHeight = ref(0);

// 报表查询参数
let reportOpts = ref({});

// origin data
let originResData = null;

let latestDate = ref(null);

// 自定义的数据转换控制器，对数据表中指定类型的数据做二次处理，比如数学运算，加字符前后缀等
let valueTransFuncs = {
    float: (value, ck, rk) => {
        return (value / 10000).toFixed(2);
    },
};

// 设置表格数据
function setTableData(data) {
    reportList.value = genListByHashAndMap(
        data.data,
        data.rows,
        isValueTransEnable.value ? valueTransFuncs : {}
    );
    // console.log(reportList.value);
}

// 是否开启自定义数据转换
let isValueTransEnable = ref(
    !route.query.vtrans || route.query.vtrans === 'true'
);

watch(isValueTransEnable, val => {
    columns.value = columns.value.map(item => {
        item.minWidth = item.key === 'name' ? item.minWidth : val ? 56 : 70;
        return item;
    });
});

function onDisplayTransChange(value) {
    setTableData(originResData);
    router.replace({ query: { ...route.query, vtrans: value } });
}

let currentReportDate = computed(() => {
    let opts = reportOpts.value;
    if (opts.current) {
        return opts.current;
    }
    return {};
});

function getReportPromise(options) {
    return options.diff
        ? FinancialAPI.diffReports(
              options.current.year,
              options.current.month,
              options.current.version,
              options.diff.year,
              options.diff.month,
              options.diff.version
          )
        : FinancialAPI.getReports(
              options.current.year,
              options.current.month,
              options.current.version
          );
}

function onVersionReady(options) {
    reportOpts.value = options;
    getReportPromise(options)
        .then(res => {
            loading.value = false;
            if (res.error_no !== 0) {
                message.error(res.error_msg || '未知异常导致加载失败');
                return;
            }
            originResData = res.data;
            setTableData(res.data);
            columns.value = genColumns(res.data.columns);
            latestDate.value = res.data.last_date;
        })
        .catch(err => {
            message.error('未知异常导致加载失败');
            loading.value = false;
        });
}

function onVersionChange(options) {
    if (loading.value) {
        return;
    }
    loading.value = true;
    reportOpts.value = options;

    getReportPromise(options)
        .then(res => {
            loading.value = false;
            if (res.error_no !== 0) {
                message.error(res.error_msg || '未知异常导致加载失败');
                return;
            }
            originResData = res.data;
            setTableData(res.data);
            columns.value = genColumns(res.data.columns);
        })
        .catch(err => {
            message.error('未知异常导致加载失败');
            loading.value = false;
        });
}

// 创建快照
let showSnapShotCreate = ref(false);
let createSnapShopForm = ref();
let createSnapShopRules = ref({
    versionName: [
        {
            required: true,
            validator(rule, value) {
                if (!value) {
                    return new Error('版本名称为必填项');
                } else if (/-/.test(value)) {
                    return new Error('版本名称中不能包含“-”符合');
                }
                return true;
            },
            trigger: ['blur', 'input'],
        },
    ],
});

let createSnapShowModel = ref({
    versionName: '',
});

let createSnapLoading = ref(false);

function showSnapShotModal() {
    showSnapShotCreate.value = true;
}

function hideSnapShowModal() {
    createSnapShowModel.value.versionName = '';
    showSnapShotCreate.value = false;
}

function onCreateSnapShotSubmit() {
    createSnapShopForm.value.validate(errs => {
        if (errs) {
            return;
        }
        let { year, month } = currentReportDate.value;
        let version = createSnapShowModel.value.versionName;
        createSnapLoading.value = true;
        FinancialAPI.saveSnapShot(year, month, version)
            .then(res => {
                createSnapLoading.value = false;
                if (res.error_no !== 0) {
                    message.error(res.error_msg);
                    return;
                }
                message.success('创建快照完成！');
                vSelector.value.refresh();
                hideSnapShowModal();
            })
            .catch(err => {
                createSnapLoading.value = false;
                message.error('未知异常导致失败');
            });
    });
}

function genDiffCSVList(data, rows, config = {}) {
    // 根据数据类型处理展现形式
    const translate = translateFactory(config);
    let list = rows.map(row => {
        let originAttrs = data[row.key];
        let itemAttrs = {};
        for (let key in originAttrs) {
            let attrObj = originAttrs[key];
            let transFunc =
                translate[attrObj.type || 'default'] || translate['default'];
            itemAttrs[key] = isNullOrUndefined(attrObj.diff)
                ? transFunc(attrObj.value, key, row.key)
                : `base:${transFunc(
                      attrObj.value,
                      key,
                      row.key
                  )} dst:${transFunc(
                      attrObj.dst_value,
                      key,
                      row.key
                  )} diff:${transFunc(attrObj.diff, key, row.key)}`;
        }

        let item = {
            keyName: row.key,
            name: row.label,
            ...itemAttrs,
        };
        return item;
    });
    return list;
}

function onCSVExportClick() {
    const { year, month, version } = currentReportDate.value;
    let title = route.query.diff
        ? `导出[${route.query.base}]和[${route.query.diff}]的比对表格数据`
        : `导出${year}-${month} ${version}`;
    dialog.info({
        title,
        content: '点击确认将导出表格数据',
        positiveText: '确定',
        positiveButtonProps: {
            type: 'primary',
        },
        negativeText: '取消',
        onPositiveClick: () => {
            let csvData = route.query.diff
                ? genDiffCSVList(
                      originResData.data,
                      originResData.rows,
                      isValueTransEnable.value ? valueTransFuncs : {}
                  )
                : reportList.value;
            exportCSV(csvData, columns.value, {
                file: route.query.diff
                    ? `diff[${route.query.base}][${route.query.diff}].csv`
                    : `${year}-${month}-${version}.csv`,
            });
        },
    });
}

// lifecycle
onMounted(() => {
    tableHeight.value = document.body.clientHeight - 51 - 70;
});

window.addEventListener('resize', () => {
    tableHeight.value = document.body.clientHeight - 51 - 70;
});
</script>
